<script setup lang="ts">
interface Item {
  [key: string]: any
}

const props = defineProps<{ items?: Item[] | null }>()

const safeItems = computed(() => props.items ?? [])

const copyToClipboard = async (): Promise<void> => {
  try {
    await navigator.clipboard.writeText(JSON.stringify(safeItems.value, null, 2))
    // eslint-disable-next-line no-alert
    alert('Copied to clipboard!')
  } catch (err) {
    console.error('Failed to copy:', err)
  }
}

const downloadJson = (): void => {
  const blob = new Blob([JSON.stringify(safeItems.value, null, 2)], { type: 'application/json' })
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = 'data.json'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
</script>

<template>
  <div class="p-4 content">
    <div class="flex justify-between mb-4">
      <h2 class="font-bold">Array List [{{ items ? items.length : 0 }}]</h2>
      <div class="flex space-x-2">
        <button class="px-3 py-1 bg-blue-500 text-white rounded" @click="copyToClipboard">
          Copy JSON
        </button>

        <button class="px-3 py-1 bg-green-500 text-white rounded" @click="downloadJson">
          Download JSON
        </button>
      </div>
    </div>
    <div v-if="items && items.length" class="grid gap-4">
      <div v-for="(item, index) in items" :key="index" class="p-4 border rounded-lg bg-gray-100">
        <h3 class="font-semibold">Item {{ index + 1 }}</h3>
        <ul class="mt-2">
          <li v-for="(value, key) in item" :key="key" class="">
            <strong>{{ key }}:</strong> {{ value }}
          </li>
        </ul>
      </div>
    </div>
    <p v-else class="text-gray-500">No data available</p>
  </div>
</template>

<style scoped>
.content {
  font-size: 12px;
}
button {
  background-color: green;
  margin-right: 5px;
}
button:hover {
  opacity: 0.8;
}
</style>
